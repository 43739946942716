import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlogCard from '../components/BlogCard';

const StyledSection = styled.section`
  background-color: white;
  .dateline {
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: -3px;
  }
  h1 {
    font-size: 2.6rem;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 2.8rem;
    letter-spacing: 2.5px;
    margin-top: 0;
    margin-bottom: 0;
    transition: all 200ms linear;
    @media (max-width: 690px) {
      font-size: 1.8rem;
      line-height: 2rem;
      letter-spacing: 2px;
    }
  }

  .cats,
  .tags {
    margin-top: 0.8rem;
    margin-bottom: 1rem;
    h4 {
      display: inline-block;
      margin: 0;
      color: #222;
    }
    .taglist {
      display: inline-block;
      padding-left: 0;
      margin: 0 0 0 0.6rem;
    }
    .taglist li {
      border: 1px solid #555;
      padding: 1px 6px;
      border-radius: 100px;
      display: inline-block;
      text-transform: uppercase;
      font-family: 'Oswald';
      font-size: 12px;
      margin-right: 0.6rem;
      margin-top: 0.6rem;
      a {
        color: #555;
        text-decoration: none;
      }
      &:hover {
        background-color: #009bce;
        border-color: #006080;
        a {
          color: white;
        }
      }
    }
  }
  .authorBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 18px;
    line-height: 20px;
    color: #444;
    margin-bottom: 1.5rem;
    .gatsby-image-wrapper {
      float: left;
      margin-right: 1rem;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    }
    &:after {
      content: '';
      clear: both;
      display: table;
    }
    p {
      margin-top: 0;
      margin-bottom: 8px;
    }
    p:nth-of-type(1),
    p:nth-of-type(1) a {
      color: #009bce;
      font-weight: 800;
      letter-spacing: 1.6px;
    }
    a {
      text-decoration: none;
      cursor: pointer;
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .container {
    color: black;
    max-width: 1500px;
    padding: 55px 0 55px 0;
    margin: 0 auto;
    box-sizing: border-box;

    .columns {
      display: flex;
      flex-wrap: nowrap;
      .column:nth-child(1) {
        flex: 2 1 calc(100% - 400px);
        border-right: 1px solid black;
        padding: 0 55px;
        max-width: calc(100% - 511px);
      }
    }

    .related {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 400px;
      width: 100%;
      /* flex: 1 1 282px; */
      width: 400px;
      padding: 0 55px;
      box-sizing: border-box;
      @media (max-width: 690px) {
        padding: 0;
      }
      h3 {
        align-self: baseline;
        text-transform: uppercase;
        font-size: 1.9rem;
        font-weight: normal;
        margin-top: 0;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          max-width: 280px;
          margin-bottom: 2rem;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .container {
      .columns {
        flex-wrap: wrap;
        > div {
          max-width: 100%;
          flex: 0 0 100%;
        }
        .column:nth-child(1) {
          border-right: 0;
          max-width: calc(100% - 110px);
        }
        .related {
          min-width: 0;
          ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              max-width: 48%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media (max-width: 690px) {
    .container {
      padding: 20px;
      .columns {
        .column:nth-child(1) {
          padding: 0;
          border-right: 0;
          max-width: 100%;
        }
        .dateline {
          margin-top: 0;
        }
        .related {
          ul {
            max-width: 100%;
            li {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .content {
    max-width: 1000px !important;
    margin: auto;
    h2 {
      text-transform: uppercase;
      color: #009bce;
      font-size: 1.3rem;
      letter-spacing: 1.1px;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
    h3 {
      text-transform: uppercase;
      color: #000;
      font-size: 1.2rem;
      letter-spacing: 1.1px;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p,
    ul,
    ol,
    table,
    a {
      font-size: 1.3rem;
      line-height: 1.7;
      font-weight: 300;
      margin-top: 0;
      transition: all 200ms linear;
      @media (max-width: 690px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    .table-wrapper {
      /* overflow-x: scroll;
      width: 100%; */
    }
    table {
      tr {
        td {
          width: 33.33%;
          &:first-of-type {
            min-width: 170px;
            @media (max-width: 1024px) {
              min-width: 0;
            }
          }
        }
      }
    }
    a {
      color: #0095c8;
      &:hover {
        color: inherit;
      }
    }
    .fontsmall {
      p,
      ol li,
      ul li,
      a {
        font-size: 0.8rem;
      }
    }
    .alignleft {
      float: left;
      margin: 0 1.8rem 0.5rem 0;
      @media (max-width: 900px) {
        float: none;
        clear: both;
        padding: 0 20px;
      }
    }
    .aligncenter {
      display: block;
      margin: 0 auto 1rem;
      img {
        margin: 0 auto;
        display: block;
      }
    }
    .alignright {
      float: right;
      padding-left: 15px;
      padding-bottom: 8px;
      @media (max-width: 900px) {
        float: none;
        clear: both;
        padding: 0 20px;
        margin: 0 auto;
        display: block;
      }
    }
    iframe {
      display: block;
      margin: 0 auto;
    }
    figure {
      margin: 0 0 2rem 0;
      max-width: 800px;
      width: auto !important;
    }
    figcaption {
      font-size: 0.9rem;
    }
    .gatsby-image-wrapper {
      max-width: 100% !important;
    }
    img {
      width: auto !important;
      max-width: 100% !important;
      height: auto;
    }
    strong {
      font-weight: 500;
    }
    blockquote {
      background: linear-gradient(to right, rgba(0, 149, 200, 0.2), transparent);
      border-left: 5px solid #0095c8;
      padding: 20px;
      > p {
        margin: 0;
      }
      @media (max-width: 690px) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    iframe.giphy-embed {
      width: 100% !important;
      pointer-events: none;
    }
    /* @media (max-width: 900px) { */
    iframe[src^="https://www.facebook"],
    iframe[src^="https://www.youtube"],
      iframe[src^="https://player.vimeo"],
      iframe[src^="https://embed-ssl.ted"]
    {
      max-width: 100% !important;
    }
    /* } */
  }
  .back {
    font-family: 'Oswald';
    text-decoration: none;
    display: inline-block;
    color: #009bce;
    font-size: 1.2rem;
    &:before {
      content: '<';
      padding-right: 5px;
    }
  }
`;

const BlogPostTemplate = ({ content, categories, tags, title, date, hero, author, related_posts }) => {
  const filteredCategories = categories.filter(
    (val) => val.slug != 'uncategorized' && val.slug != 'remove-from-related-content' && val.slug != 'instant-articles'
  );
  const replacedContent = content.replace(
    /http(s)?:\/\/(internal\.)?goelement\.com\/blog\/wp-content\/uploads\//g,
    '/wp-content/uploads/'
  );
  return (
    <>
      {hero.localFile && hero.localFile.childImageSharp && (
        <Hero
          heroImg={hero.localFile.childImageSharp.gatsbyImageData}
          alt_text={hero.alt_text}
          isBlog={true}
          label="Water Cooler"
        />
      )}
      <StyledSection className="section">
        <div className="container">
          <div className="columns">
            <div className="column blog-post-single is-10 is-offset-1">
              <Link to="/blog/" className="back">
                Back to All Articles
              </Link>
              <p className="dateline">{date}</p>
              <h1
                className="title is-size-2 has-text-weight-bold is-bold-light"
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {filteredCategories && filteredCategories.length ? (
                <div className="cats">
                  <h4>Topic: </h4>
                  <ul className="taglist">
                    {filteredCategories.map((category) => (
                      <li key={`${category.slug}cat`}>
                        <Link to={`/blog/category/${category.slug}/`}>{category.name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {author.custom && author.custom.imageCasual && (
                <div className="authorBlock">
                  <Link to={`/blog/author/${author.slug}/`}>
                    <GatsbyImage
                      image={author.custom.imageCasual.localFile.childImageSharp.gatsbyImageData}
                      style={{
                        borderRadius: '100%',
                        width: '90px',
                        height: '90px',
                      }}
                      alt={author.name}
                    />
                  </Link>
                  <div>
                    <p>
                      <Link to={`/blog/author/${author.slug}/`}>{author.name}</Link>
                    </p>
                    <p>{author.custom.role}</p>
                  </div>
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: replacedContent }} className="content" />
              <div style={{ marginTop: `2rem`, marginBottom: `2rem` }}>
                {false && tags && tags.length ? (
                  <div className="tags">
                    <h4>Tags</h4>
                    <ul className="taglist">
                      {tags.map((tag) => (
                        <li key={`${tag}tag`}>
                          <Link to={`/tags/${tag.slug}/`}>{tag.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
                <Link to="/blog/" className="back">
                  Back to All Articles
                </Link>
              </div>
            </div>
            <div className="column related">
              <h3>RELATED ARTICLES</h3>
              <ul>
                {related_posts.map((node) => (
                  <BlogCard node={node} key={node.id} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </StyledSection>
    </>
  );
};

const BlogPost = ({ data }) => {
  const {
    wpPost: post,
    site: { siteMetadata: siteMeta },
  } = data;

  const meta_items = [];
  meta_items.push({ property: 'og:type', content: 'article' });
  meta_items.push({ property: 'og:url', content: siteMeta.siteUrl + '/blog/' + post.slug });
  if (post.seo.metaDesc) {
    meta_items.push({
      name: 'description',
      content: post.seo.metaDesc > '' ? post.seo.metaDesc : post.excerpt,
    });
  }
  if (post.seo.opengraphImage && post.seo.opengraphImage.localFile) {
    meta_items.push({
      property: 'og:image',
      content: siteMeta.siteUrl + post.seo.opengraphImage.localFile.publicURL,
    });
  }
  //Some of the old blog posts have a title that ends with " - ELEMENT", but we are already adding " | ELEMENT" in the title tag, so we need to remove the " - ELEMENT" from the title
  let pageTitle = post.seo.title > '' ? post.seo.title : post.title;
  pageTitle = pageTitle.replace(/\s*-\s*ELEMENT\s*$/, '');
  meta_items.push({ property: 'og:title', content: pageTitle + ' | ELEMENT' });
  return (
    <Layout>
      <Helmet title={pageTitle + ` | ELEMENT`} meta={meta_items}>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@graph": [
                {
                  "@type": "Organization",
                  "@id": "https://goelement.com/#organization",
                  "url": "https://goelement.com/",
                  "name": "ELEMENT",
                  "sameAs": [
                    "https://www.facebook.com/goElement/",
                    "https://www.linkedin.com/company/element-creative",
                    "https://twitter.com/goelement"
                  ],
                  "logo": {
                    "@type": "ImageObject",
                    "@id": "https://goelement.com/#logo",
                    "url": "https://goelement.com/assets/Element_Logo_CMYK_641_423_HR.png",
                    "caption": ""
                  },
                  "image": {
                    "@id": "https://goelement.com/#logo"
                  }
                },
                {
                  "@type": "WebSite",
                  "@id": "https://goelement.com/#website",
                  "url": "https://goelement.com/",
                  "name": "ELEMENT",
                  "publisher": {
                    "@id": "https://goelement.com/#organization"
                  }
                },
                {
                  "@type": "WebPage",
                  "@id": "https://goelement.com/blog/${post.slug}/#webpage",
                  "url": "https://goelement.com/blog/${post.slug}",
                  "inLanguage": "en-US",
                  "name": "${post.seo.title}",
                  "isPartOf": {
                    "@id": "https://goelement.com/#website"
                  },
                  "image": {
                    "@type": "ImageObject",
                    "@id": "https://goelement.com/blog/${post.slug}/#primaryimage",
                    "url": "https://goelement.com${post.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src}"
                  },
                  "primaryImageOfPage": {
                    "@id": "https://goelement.com/blog/${post.slug}/#primaryimage"
                  },
                  "datePublished": "${post.date}",
                  "dateModified": "${post.modified}",
                  "description": "${post.seo.metaDesc}"
                },
                {
                  "@type": "Article",
                  "@id": "https://goelement.com/blog/${post.slug}/#article",
                  "isPartOf": {
                    "@id": "https://goelement.com/blog/${post.slug}/#webpage"
                  },
                  "author": {
                    "@id": "https://goelement.com/blog/author/${post.author.slug}/#author"
                  },
                  "headline": "${post.seo.title}",
                  "datePublished": "${post.date}",
                  "dateModified": "${post.modified}",
                  "commentCount": 0,
                  "mainEntityOfPage": {
                    "@id": "https://goelement.com/blog/${post.slug}/#webpage"
                  },
                  "publisher": {
                    "@id": "https://goelement.com/#organization"
                  },
                  "articleSection": "Content Marketing, Marketing Strategy",
                  "image": {
                    "@type": "ImageObject",
                    "@id": "https://goelement.com/blog/${post.slug}/#primaryimage",
                    "url": "https://goelement.com${post.featuredImage.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src}"
                  }
                },
                {
                  "@type": "Person",
                  "@id": "https://goelement.com/blog/author/${post.author.slug}/#author",
                  "name": "${post.author.name}",
                  "sameAs": []
                }
              ]
            }
          `}
        </script>
      </Helmet>
      <BlogPostTemplate
        content={post.content}
        categories={post.categories.nodes}
        tags={post.tags}
        title={post.title}
        date={post.date}
        hero={post.featuredImage.node}
        author={post.author.node}
        related_posts={post.random_related ? post.random_related : []}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      content
      modified
      date(formatString: "D MMMM, YYYY")
      excerpt
      seo {
        title
        metaDesc
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          id
          name
          slug
          url
          description
          custom {
            imageCasual {
              sourceUrl
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 160, quality: 90, transformOptions: { cropFocus: CENTER })
                }
              }
            }
            role
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1150, quality: 93)
            }
          }
        }
      }
      random_related {
        id
        slug
        title
        date(formatString: "D MMMM, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 500, quality: 100)
              }
            }
          }
        }
        author {
          node {
            slug
            name
            custom {
              imageCasual {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FIXED
                      width: 80
                      height: 60
                      quality: 90
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
